<template>
  <div class="install-body">
    <div class="install-header">
      <div class="header-content">
        <a-row>
          <a-col :span="12"
            ><img
              class="install-logo"
              :src="require('@/assets/img/logo-login.png')"
            />
            <span class="title">开源框架</span>
          </a-col>
          <a-col :span="12">
            <div class="header-right">
              <a-button type="text">官网</a-button>
              <a-button type="text">论坛</a-button>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="install-content">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
      <div class="steps-content">
        <a-card v-if="current == 0" title="阅读许可协议" :bordered="false">
          <div class="step-body" v-html="agreement_detail"></div>
        </a-card>
        <a-card
          v-if="current == 1"
          :title="steps[current].title"
          :bordered="false"
        >
          <div>
            <a-spin :spinning="spinning">
              <p>服务器信息</p>
              <a-table
                class="server-card"
                :dataSource="serverDataSource"
                :columns="serverColumns"
                :pagination="false"
              />
              <p>系统环境检测</p>
              <a-table
                class="server-card"
                :dataSource="detectionDataSource"
                :columns="detectionColumns"
                :pagination="false"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'status'">
                    <CheckOutlined v-if="record.status" class="correct-icon" />
                    <CloseOutlined v-else class="error-icon" />
                  </template>
                </template>
              </a-table>
              <p>目录权限检测</p>
              <a-table
                class="server-card"
                :dataSource="dirsDataSource"
                :columns="dirsColumns"
                :pagination="false"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'is_readable'">
                    <CheckOutlined
                      v-if="record.is_readable"
                      class="correct-icon"
                    />
                    <CloseOutlined v-else class="error-icon" />
                  </template>
                  <template v-if="column.key === 'is_write'">
                    <CheckOutlined
                      v-if="record.is_write"
                      class="correct-icon"
                    />
                    <CloseOutlined v-else class="error-icon" />
                  </template>
                </template>
              </a-table>
            </a-spin>
          </div>
        </a-card>
        <a-card
          v-if="current == 2"
          :title="steps[current].title"
          :bordered="false"
        >
          <div>
            <a-form
              :model="formState"
              name="db"
              ref="formRef"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
              autocomplete="off"
              @finish="onFinish"
            >
              <a-divider orientation="left">数据库配置</a-divider>
              <a-form-item
                label="数据库主机"
                name="dbhost"
                :rules="[{ required: true, message: '请输入数据库主机地址' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.dbhost"
                  @blur="testDb"
                  placeholder="请输入数据库主机地址"
                />
              </a-form-item>

              <a-form-item
                label="数据库端口"
                name="dbport"
                :rules="[{ required: true, message: '请输入数据库端口' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.dbport"
                  @blur="testDb"
                  placeholder="请输入数据库端口"
                />
              </a-form-item>

              <a-form-item
                label="数据库用户"
                name="dbuser"
                :rules="[{ required: true, message: '请输入数据库用户' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.dbuser"
                  @blur="testDb"
                  placeholder="请输入数据库用户"
                />
                <span class="db-extra" v-if="dbLinkInfo.status > 0"
                  ><CheckOutlined class="tips-icon correct-icon" />{{
                    dbLinkInfo.message
                  }}</span
                >
                <span
                  class="db-extra"
                  v-else-if="dbLinkInfo.status < 1 && dbLinkInfo.message != ''"
                  ><CloseOutlined class="tips-icon error-icon" />{{
                    dbLinkInfo.message
                  }}</span
                >
              </a-form-item>

              <a-form-item
                label="数据库密码"
                name="dbpwd"
                :rules="[{ required: true, message: '请输入数据库密码' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.dbpwd"
                  @blur="testDb"
                  placeholder="请输入数据库密码"
                />
              </a-form-item>

              <a-form-item
                label="数据库名称"
                name="dbname"
                :rules="[{ required: true, message: '请输入数据库名称' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.dbname"
                  @blur="testDb"
                  placeholder="请输入数据库名称"
                />
              </a-form-item>
              <a-form-item label="数据库前缀" name="dbprefix">
                <a-input
                  class="medium-input"
                  v-model:value="formState.dbprefix"
                  placeholder="请输入数据库前缀"
                />
              </a-form-item>
              <a-form-item label="数据库编码" name="dbcoding">
                UTF8
              </a-form-item>

              <a-divider orientation="left">网站配置</a-divider>
              <a-form-item
                label="网站标题"
                name="sitename"
                :rules="[{ required: true, message: '请输入网站标题' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.sitename"
                  placeholder="请输入网站标题"
                />
              </a-form-item>
              <a-form-item
                label="管理员名称"
                name="username"
                :rules="[{ required: true, message: '请输入管理员名称' }]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.username"
                  placeholder="请输入管理员名称"
                />
              </a-form-item>
              <a-form-item
                label="管理员密码"
                name="password"
                :rules="[
                  {
                    trigger: 'change',
                    validator: validatorPass,
                  },
                ]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.password"
                  placeholder="请输入管理员密码"
                />
              </a-form-item>
              <a-form-item
                label="确认密码"
                name="checkpass"
                :rules="[
                  {
                    trigger: 'change',
                    validator: checkPass,
                  },
                ]"
              >
                <a-input
                  class="medium-input"
                  v-model:value="formState.checkpass"
                  placeholder="请输入确认密码"
                />
              </a-form-item>
            </a-form>
          </div>
        </a-card>
        <a-card
          v-if="current == 3"
          :title="steps[current].title"
          :bordered="false"
        >
          <div class="step-body">
            <div class="install-success" v-if="install_success">
              <CheckCircleOutlined class="install-success-icon" />
              <div class="result-title">安装成功</div>
            </div>
            <div class="install-fail" v-else>
              <CloseCircleOutlined class="install-fail-icon" />
              <div class="result-title">安装失败</div>
              <div class="fail-message">
                安装失败，请检测系统环境和网络是否正常
              </div>
            </div>
          </div>
        </a-card>
      </div>
      <div class="steps-action">
        <a-button
          v-if="current == steps.length - 1"
          type="primary"
          @click="complete"
        >
          完成安装
        </a-button>
        <a-button v-if="current > 0 && current < 3" @click="prev"
          >上一步</a-button
        >
        <span v-if="current == 0"
          ><a-checkbox v-model:checked="checked"
            >我已经阅读并同意此协议</a-checkbox
          ></span
        >
        <a-button
          style="margin-left: 10px"
          v-if="current == 0"
          type="primary"
          :disabled="!checked"
          @click="step1"
          >继续</a-button
        >
        <a-button
          style="margin-left: 10px"
          v-if="current == 1"
          type="primary"
          :disabled="!is_step2"
          @click="step2"
          >继续</a-button
        >

        <a-button
          style="margin-left: 10px"
          v-if="current == 2"
          type="primary"
          @click="onFinish"
          >继续2</a-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import {
  CheckOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import * as Api from "./api";
import "./style.less";
export default defineComponent({
  components: {
    CheckOutlined,
    CloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup() {
    // 当前进度
    const current = ref(0);

    // 上一步
    const prev = () => {
      current.value--;
    };

    const router = useRouter();

    // 第一步，许可协议
    const agreement_detail = ref();

    // 是否同意
    const checked = ref(false);

    // 第二步检测环境参数-环境监测是否通过
    const is_step2 = ref(false);

    // 请求loadding
    const spinning = ref(true);

    // 环境检测参数
    const serverDataSource = ref([]);
    const detectionDataSource = ref([]);
    const dirsDataSource = ref([]);

    // 第一步同意协议后 请求环境相关参数
    const step1 = () => {
      current.value++;
      Api.environment().then((res) => {
        console.log(res);
        serverDataSource.value = res.data.server;
        detectionDataSource.value = res.data.detection;
        dirsDataSource.value = res.data.dirs_list;
        is_step2.value = res.data.is_continue;
        spinning.value = false;
      });
    };

    // 第二步继续
    const step2 = () => {
      current.value++;
    };

    // 第三步，数据库及站点配置
    const formRef = ref();

    const formState = reactive({
      dbhost: "localhost",
      dbport: "3306",
      dbuser: "",
      dbpwd: "",
      dbname: "",
      dbprefix: "",
      sitename: "",
      username: "",
      password: "",
      checkpass: "",
    });

    let validatorPass = async (_rule, value) => {
      if (value === "") {
        return Promise.reject("请输入管理员密码");
      } else {
        return Promise.resolve();
      }
    };

    let checkPass = async (_rule, value) => {
      if (value === "") {
        return Promise.reject("请输入确认密码");
      } else if (value !== formState.password) {
        return Promise.reject("两次密码不一致！");
      } else {
        return Promise.resolve();
      }
    };

    // 获取数据库信息和站点信息开始安装
    const onFinish = (values) => {
      formRef.value.validateFields();
      console.log("Success:", values);

      // 提交安装信息
      Api.handle_install(formState).then((res) => {
        if (res.status === 200) {
          message.success(res.message);
          current.value++;
        } else {
          message.error(res.message);
        }
      });
    };

    // 输入数据库配置调用数据库链接测试
    const dbLinkInfo = reactive({
      status: -2,
      message: "",
    });

    // 检测数据库连接
    const testDb = () => {
      Api.test_db(formState).then((res) => {
        dbLinkInfo.status = res.data.status;
        dbLinkInfo.message = res.data.message;
      });
    };

    //是否安装成功
    const install_success = ref(true);

    // 完成安装跳转
    const complete = () => {
      message.success("Processing complete!");
      router.push("/index");
    };

    // 安装初始化获取协议数据
    const getAgreeMent = () => {
      Api.agreement().then((res) => {
        agreement_detail.value = res.data;
      });
    };

    onMounted(() => {
      getAgreeMent();
    });

    return {
      complete,
      current,
      steps: [
        {
          title: "许可协议",
          content: "许可协议",
        },
        {
          title: "环境检测",
          content: "环境检测",
        },
        {
          title: "参数配置",
          content: "参数配置",
        },
        {
          title: "安装完成",
          content: "安装完成",
        },
      ],
      step2,
      prev,
      agreement_detail,
      checked,
      step1,
      is_step2,
      dbLinkInfo,
      spinning,
      serverDataSource,
      serverColumns: [
        {
          title: "参数",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "值",
          dataIndex: "value",
          key: "value",
        },
      ],
      detectionDataSource,
      detectionColumns: [
        {
          title: "需开启的变量或函数",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "要求",
          dataIndex: "value",
          key: "value",
        },
        {
          title: "实际状态及建议",
          dataIndex: "status",
          key: "status",
        },
      ],
      dirsDataSource,
      dirsColumns: [
        {
          title: "目录名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "读取权限",
          dataIndex: "is_readable",
          key: "is_readable",
        },
        {
          title: "写入权限",
          dataIndex: "is_write",
          key: "is_write",
        },
      ],
      formRef,
      formState,
      validatorPass,
      checkPass,
      onFinish,
      testDb,
      install_success,
    };
  },
});
</script>