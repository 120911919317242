import request from '@/utils/http'

/**
 * 协议
 * @param payload
 */
export function agreement(payload) {
    return request({ url: '/Install/agreement', method: 'post', data: payload })
}

/**
 * 参数检测
 * @param payload
 */
export function environment(payload) {
    return request({ url: '/Install/environment', method: 'post', data: payload })
}

/**
 * 数据库连接测试
 * @param payload
 */
export function test_db(payload) {
    return request({ url: '/Install/test_db', method: 'post', data: payload })
}

/**
 * 安装系统
 * @param payload
 */
export function handle_install(payload) {
    return request({ url: '/Install/handle', method: 'post', data: payload })
}